
.navbar nav-link {
    text-transform: uppercase;

}
.container-modified{
    @media (min-width: 992px){
        max-width: fit-content!important;
    }

}

.customAnimation {
    height: 100%;
    z-index: 1;
    width: 100%;
    position: absolute;
    left: 0%;
    top: 0;
    background-color: #FECB18;
    animation: moveLeft 500ms 800ms ease-in-out forwards;

}

.animationContainer {
    overflow: hidden;
    position: relative;

}


@keyframes moveLeft {
    from {
        left: 0%
    }
    to {
        left: 100%;
        display: none;
    }

}

.twitterFeedContainer {
    height: 90vh;
    overflow-y: scroll;
}
.twitterFeedSideContainer{
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
}


.logoRotate {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }



.mouse-wrap {
    position: relative;
    display: inline-block;
    left: 50%;
    margin-top: 10% !important;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 50px;
    z-index: 9;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .mouse-wrap {
        position: relative;
        display: inline-block;
        left: 50%;
        margin-top: 20% !important;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 50px;
        z-index: 9;
    }

}


@media (max-width: 768px){
    .mouse-wrap {
        position: relative;
        display: inline-block;
        left: 50%;
        margin-top: 50% !important;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        bottom: 50px;
        z-index: 9;
    }

}

 .mouse {
    position: absolute;
    width: 22px;
    height: 42px;
    bottom: 30px;
    display: block;
    left: 50%;
    margin-left: -12px;
    border-radius: 15px;
    border: 2px solid #fff;
    -webkit-animation: intro 2s;
    animation: intro 2s;
}


.scroll {
    display: block;
    width: 3px;
    height: 3px;
    margin: 6px auto;
    border-radius: 4px;
    background: #fff;
    -webkit-animation: finger 2s infinite;
    animation: finger 2s infinite;
}

.mouse-label {
    top: 0;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .05rem;
    text-transform: uppercase;
}
.pulse {

    animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
    from { transform: scale(0.8); }
    to { transform: scale(1.2); }
}


.socialMediaHeader{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 32px;
    padding: 24px 10px;
    color:white;
}


/*start loading animation*/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #FECB18;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}

/*end loading animation*/

.center-text{
    height: 100%;
    display: flex;
    margin: auto;
    align-items: center;
    margin-top:16px


}

.column-one{
    color:white;
    text-align: left; padding: 8px; width: 90%;
}
.column-two{
    color:white;
    text-align: left; padding: 8px;
}

.accordion-container{
    max-width: 600px;
    margin: auto;
}

.custom-accordion-item{
    border:1px solid rgba(255, 255, 255, 0.2)!important;
    margin-top:1.5rem

}

.payment-button-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.payment-icon{
    height:30px;
    background-color:#FECB18;

}
.video-card{
    background-color: #232323 !important;
    width:440px;
    border-radius: 10px;
}
.videos-section{
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px;
}


/*custom scrollbar css*/
.tile--custom-scrollbar::-webkit-scrollbar {
    width: 12px;
    background-color: #eff1f5;
}

.tile--custom-scrollbar::-webkit-scrollbar-track{
    border-radius: 3px;
    background-color: transparent;
}

.tile--custom-scrollbar::-webkit-scrollbar-thumb{
    border-radius:5px;
    background-color:#515769;
    border:2px solid #eff1f5
}

/*end of custom scrollbar*/


.testimony-image{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    background-color: #414141;
    padding:5px
}

.video-card-new{
    background-color: #232323 !important;
    width:411px;
    height:200px;
    border-radius: 10px;
}
.center-review{
    margin-left: 70px;
    margin-right: 70px;
}
.map{
    float: left;
    width: 100%;
    height: 450px;
    border: none;
    overflow: hidden;
    text-align: center;
}

@media only screen and (max-width: 991px) {
    .map {
        height: 450px;
        width: 100%;
        text-align: center;
    }
}

.disable-package{
    background-color:black;
    z-index: 100;
    position: absolute;
    opacity: 60%;
    height: 100%;
    width:100%;
}
.disable-package-container{
    position: relative;
}
.coming-soon-container{
    background-color:#F8C500 ;
}

.card-price-selected {
    /*min-width: 0;*/
    word-wrap: break-word;
    background-color: #373737;
    border: none;
    /* padding: 10px; */
    border-radius: 5px !important;
    border: 10px solid #FBCD1D;
}





.circle-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    float: left;
    margin: 10px;
}

.icon {
    position: absolute;
    color: #fff;
    font-size: 55px;
    top: 45px;
    left: 50px;
    transform: translate(-50%, -50%);
}

.circle {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 2.5px;
    background-clip: content-box;
    animation: spin 2s linear infinite;
}

.circle-wrapper:active .circle {
    animation: spin 0.6s linear infinite;
}

.success {
    /*background-color: #4BB543;*/
    border-style: solid;
    border-width: 3px;
    border-top-color: #dca73a;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}

.error {
    background-color: #CA0B00;
    border: 2.5px dashed #CA0B00;
}



@keyframes spin {
    100% {
        transform: rotateZ(360deg);
    }
}



/*timeslotcard*/

.time-section-container{
    border-radius: 10px;
    border: 1px solid #FFBF00;
    width: 550px;
    max-width: 100%;
}

.slot-container{
    display:flex;
    margin:10px;
    background-color: #232323;
    color: white;
    padding:10px;
    border-radius: 10px;
}
.selected-slot{
    border: 1px solid #FFBF00
}



.success-screen-container{
    background:white;
    border-radius:10px;

}
.success-screen{
    background:rgb(0,128,0);
    border-radius:10px;
    padding:20px;
    opacity: 80%;
    height:400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.error-screen-container{
    background:white;
    border-radius:10px;

}
.error-screen{
    background:red;
    border-radius:10px;
    padding:20px;
    opacity: 80%;
    height:400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.mentorship-image-container{
    display: flex;
    justify-content: center;
}


.heading-91-new {
    font-family: sans-serif;
    /* margin: 10px auto; */
    text-align: left;
    color: #fff;
    font-size: 22px;
    position: relative;
}

.head-21-new {
    font-family: 'barlow', sans-serif;
    font-size: 16px;
    color: #D8D8D8;
    text-align: left;
    transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}



.parag-pricing-0-new {
    text-align: left; padding-top: 2px;
    font-family: 'barlow', sans-serif;
    font-size: 14px;
    color: #FFF;
    margin-bottom: 0rem
}

@media (max-width: 768px){

    .heading-91-new {
        font-family: sans-serif;
        /* margin: 10px auto; */
        text-align: center;
        color: #fff;
        font-size: 22px;
        position: relative;
    }
    .head-21-new {
        font-family: 'barlow', sans-serif;
        font-size: 16px;
        color: #D8D8D8;
        text-align: center;
        transform: scale(0.94);
        animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
    }

    .parag-pricing-0-new {
        text-align: center; padding-top: 2px;
        font-family: 'barlow', sans-serif;
        font-size: 14px;
        color: #FFF;
        margin-bottom: 0rem
    }
}







/*=========================================================
*/


/*
Fade content bs-carousel with hero headers
Code snippet by maridlcrmn (Follow me on Twitter @maridlcrmn) for Bootsnipp.com
Image credits: unsplash.com
*/

/********************************/
/*       Fade Bs-carousel       */
/********************************/
.fade-carousel {
    position: relative;
    height: 100vh;
}
.fade-carousel .carousel-inner .item {
    height: 100vh;
}
.fade-carousel .carousel-indicators > li {
    margin: 0 2px;
    background-color: #f39c12;
    border-color: #f39c12;
    opacity: .7;
}
.fade-carousel .carousel-indicators > li.active {
    width: 10px;
    height: 10px;
    opacity: 1;
}

/********************************/
/*          Hero Headers        */
/********************************/
.hero {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 0 rgba(0,0,0,.75);
    -webkit-transform: translate3d(-50%,-50%,0);
    -moz-transform: translate3d(-50%,-50%,0);
    -ms-transform: translate3d(-50%,-50%,0);
    -o-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
}
.hero h1 {
    font-size: 6em;
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.fade-carousel .carousel-inner .item .hero {
    opacity: 0;
    -webkit-transition: 2s all ease-in-out .1s;
    -moz-transition: 2s all ease-in-out .1s;
    -ms-transition: 2s all ease-in-out .1s;
    -o-transition: 2s all ease-in-out .1s;
    transition: 2s all ease-in-out .1s;
}
.fade-carousel .carousel-inner .item.active .hero {
    opacity: 1;
    -webkit-transition: 2s all ease-in-out .1s;
    -moz-transition: 2s all ease-in-out .1s;
    -ms-transition: 2s all ease-in-out .1s;
    -o-transition: 2s all ease-in-out .1s;
    transition: 2s all ease-in-out .1s;
}

/********************************/
/*            Overlay           */
/********************************/
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #080d15;
    opacity: .7;
}

/********************************/
/*          Custom Buttons      */
/********************************/
.btn.btn-lg {padding: 10px 40px;}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
    color: #f5f5f5;
    background-color: #1abc9c;
    border-color: #1abc9c;
    outline: none;
    margin: 20px auto;
}

/********************************/
/*       Slides backgrounds     */
/********************************/
.fade-carousel .slides .slide-1,
.fade-carousel .slides .slide-2,
.fade-carousel .slides .slide-3 {
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.fade-carousel .slides .slide-1 {
    background-image: url(https://ununsplash.imgix.net/photo-1416339134316-0e91dc9ded92?q=75&fm=jpg&s=883a422e10fc4149893984019f63c818);
}
.fade-carousel .slides .slide-2 {
    background-image: url(https://ununsplash.imgix.net/photo-1416339684178-3a239570f315?q=75&fm=jpg&s=c39d9a3bf66d6566b9608a9f1f3765af);
}
.fade-carousel .slides .slide-3 {
    background-image: url(https://ununsplash.imgix.net/photo-1416339276121-ba1dfa199912?q=75&fm=jpg&s=9bf9f2ef5be5cb5eee5255e7765cb327);
}

/********************************/
/*          Media Queries       */
/********************************/
@media screen and (min-width: 980px){
    .hero { width: 980px; }
}
@media screen and (max-width: 640px){
    .hero h1 { font-size: 4em; }
}




code {
    padding: 5px 10px;
    display: inline-block;
}

.container-a {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-column-gap: 10px;
    justify-content: center;
    margin: 64px 0;
}

.field-a {
    width: 55px;
    height: 55px;
    font-size: 40px;
    text-align: center;
    /*margin:6px;*/
    margin-right: 6px !important;
    outline: none;
    border-radius: 7px;
    border: 1px solid yellow;
    transition-property: color, border, box-shadow, transform;
    transition-duration: 250ms;
}
.field-a:focus {
    outline: none;
    box-shadow: 0 0 7px #686de080;
    border: 1px solid #686de0;
    transform: scale(1.05);
}

.field-a-complete {
    border: 1px solid #6ab04c;
    color: #6ab04c;
}
.field-a-complete[disabled] {
    background: #6ab04c;
    opacity: 0.5;
    cursor: not-allowed;
}